import axios from "axios";
import languages from "./languages";
import { getError } from "./Values";
// import NetInfo from "@react-native-community/netinfo";

export const SITE_URL = process.env.REACT_APP_API_URL;
export const SITE_NAME = SITE_URL + "/api/";

export const showModalErrorCode = (error, thisProps, lang = "en") => {
  if (thisProps && thisProps.showModal && thisProps.data) {
    const err = getError(thisProps.data, error, lang);
    let description = err ? err : languages[lang].errorDescription;
    thisProps.showModal("QuestionDialog", {
      status: "danger",
      title: languages[lang].errorTitle,
      description: description + " - " + error,
      hideNoBtn: true,
    });
    return !!err;
  }
  return false;
};

const defaultParams = {
  query: null,
  post: null,
  token: null,
  lang: "en",
  showModal: undefined,
  onAnyFailed: () => {},
  onConnectionError: (error) => {},
  onError: (error, thisProps, lang = "en") => {
    if (error.code >= 400 && error.code < 500) console.log("آدرس وجود ندارد");
    else if (error.code >= 500 && error.code < 600) console.log("خطا در سرور");
    else console.log("خطا");
    showModalErrorCode(error, thisProps, lang);
    return true;
  },
  onOtherResult: () => console.log("خطای ناشناس"),
};

const execute = (method, url, onSuccess, params = defaultParams) => {
  const newParams = { ...defaultParams, ...params };

  // NetInfo.fetch().then(state => {
  //     console.log("Is connected?", state.isConnected);
  //     if(state.isConnected)
  request(method, url, onSuccess, newParams);
  //     else
  //         newParams.onDisconnected();
  // });
};

const request = (method, url, onSuccess, params = defaultParams) => {
  axios({
    method: method,
    url: SITE_NAME + url,
    params: params.query,
    data: params.post,
    headers: {
      "Content-Type": "application/json",
      ...(params.token ? { Authorization: "Bearer " + params.token } : {}),
    },
  })
    .then((response) => {
      onSuccess(response.data.data);
    })
    .catch((errorData) => {
      if (errorData.response) {
        if ("code" in errorData.response.data) {
          const done = params.onError(
            errorData.response.data.code,
            params.thisProps,
            params.lang
          );
          if (!done)
            showModalErrorCode(
              errorData.response.data.code,
              params.thisProps,
              params.lang
            );
        } else {
          params.onConnectionError(
            errorData.response,
            params.showModal,
            params.lang
          );
          showModalErrorCode(
            errorData.response.data.code,
            params.thisProps,
            params.lang
          );
        }
      } else if (errorData.request) {
        params.onOtherResult();
        showModalErrorCode(
          errorData.response.data.code,
          params.thisProps,
          params.lang
        );
      } else {
        params.onOtherResult();
        // showModalErrorCode(errorData.response.data.code, params.thisProps, params.lang);
      }
      params.onAnyFailed();
    });
};

export default execute;
