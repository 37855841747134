let errorsStrings = {
  fa: {
    emailIsNotValid: "آدرس ایمیل معتبر نمی باشد.",
    inputIsNotValid: "ورودی معتبر نمی باشد.",

    eurCurrencyAmount:
      "مبلغ ورودی می بایست بیشتر از ۵ و کمتر از ۲۵۰۰ یورو باشد.",
    irrCurrencyAmount:
      "مبلغ می بایست بزرگتر از 1000 تومان و کوچکتر از 50 میلون تومان باشد.",
    inputRequired: "وارد کردن این ورودی الزامی می باشد.",
    tokenIsNotValid: "کد تایید معتبر نمی باشد.",
  },
  en: {
    emailIsNotValid: "Email address is not valid.",
    inputIsNotValid: "Value is not valid",

    eurCurrencyAmount:
      "Amount must be lower than 2500.00 and higher than 5.00 EUR",
    irrCurrencyAmount:
      "Amount must be lower than 500,000,000 IRR and higher than 10,000 IRR.",
    inputRequired: "This field is required.",
    tokenIsNotValid: "Verification code is not valid.",
  },
};

export default errorsStrings;
